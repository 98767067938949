import { UserRoles, OrgRoles } from "../../helper/role";

const DocMenuConfig = [
  
  {
    heading: "dashboard",
    route: "/dashboard"
  },
  {
    heading: "API",
    route: "/api",
    pages: [
      {
        heading: "Library",
        route: "/api/apilib/apilist",
        svgIcon: "media/icons/duotone/files/fil001.svg",
        fontIcon: "bi-stack",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN, OrgRoles.CONSUMER+UserRoles.DEVELOPER,
          OrgRoles.ONE_SB+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.DEVELOPER]
      }, 
      {
        heading: "Monitoring",
        route: "/api/apimonitor/analytics",
        svgIcon: "media/icons/duotone/Media/Equalizer.svg",
        fontIcon: "bi-alarm",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN, OrgRoles.CONSUMER+UserRoles.DEVELOPER,
         
          OrgRoles.ONE_SB+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.DEVELOPER]
      },
      {
        heading: "Monitoring",
        route: "/api/apimonitor/analyticsManufacturer",
        svgIcon: "media/icons/duotone/Media/Equalizer.svg",
        fontIcon: "bi-stack",
        permission: [
          OrgRoles.MANUFACTURER+UserRoles.ADMIN, OrgRoles.MANUFACTURER+UserRoles.DEVELOPER,
          ]
      },
      
      {
          heading: "Logs",
          route: "/api/apimonitor/logs",
          svgIcon: "media/icons/duotone/electronics/elc001.svg",
          fontIcon: "bi-sticky",
          permission: [OrgRoles.CONSUMER+UserRoles.ADMIN, OrgRoles.CONSUMER+UserRoles.DEVELOPER,
            OrgRoles.MANUFACTURER+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.ADMIN]
      },
      
      {
        heading: "Audit Trail",
        route: "/api/audittrail",
        svgIcon: "media/icons/duotone/Communication/Urgent-mail.svg",
        fontIcon: "bi-app-indicator",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN, OrgRoles.CONSUMER+UserRoles.DEVELOPER,
          OrgRoles.MANUFACTURER+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.ADMIN]
    },

      {
        heading: "Generate API Key",
        route: "/api/accountoverview/apikey",
        svgIcon: "media/icons/duotone/coding/cod001.svg",
        fontIcon: "bi-alarm",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN]
    },
    // {
    //   heading: "API Health Dashboard",
    //   route: "/api/health-dashboard",
    //   fontIcon: "bi-stack",
    //   permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.CONSUMER+UserRoles.DEVELOPER, 
    //     OrgRoles.ONE_SB+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.DEVELOPER]

    // },
    // {
    //   heading: "API Health Dashboard",
    //   route: "/api/kpi-detail",
    //   fontIcon: "bi-stack",
    //   permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.CONSUMER+UserRoles.DEVELOPER]

    // },
    // {
    //   heading: "API Health KPI Dashboard",
    //   route: "/api/kpi-dashboard",
    //   fontIcon: "bi-stack",
    //   permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.CONSUMER+UserRoles.DEVELOPER, 
    //     OrgRoles.ONE_SB+UserRoles.ADMIN, OrgRoles.ONE_SB+UserRoles.DEVELOPER]

    // },
    ],
  },
  {
    heading: "Manage",
    route: "/settings",
    pages: 
    [
      {
        heading: "Users",
        route: "/settings/account/usermanagement",
        fontIcon: "bi-alarm",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.MANUFACTURER+UserRoles.ADMIN,
          OrgRoles.ONE_SB+UserRoles.ADMIN]
      },
      {
        heading: "Organizations",
        route: "/settings/account/manageorganization",
        fontIcon: "bi-app-indicator",
        permission: [OrgRoles.ONE_SB+UserRoles.ADMIN]
      },
      {
        heading: "Configure Consumers",
        route: "/settings/configureconsumer",
        fontIcon: "bi-sticky",
        permission: [OrgRoles.MANUFACTURER+UserRoles.ADMIN, OrgRoles.MANUFACTURER+UserRoles.DEVELOPER
          ]
      },
      {
        heading: "Configure Manufacturer",
        route: "/settings/configuremanufacturer",
        fontIcon: "bi-sticky",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN, OrgRoles.CONSUMER+UserRoles.DEVELOPER
          ]
      },
       //CMS
       {
        heading: " Credentials Configuration",
        route: "/settings/account/ConfigureCredentials",
        fontIcon: "bi-gear-wide-connected",
        permission: [OrgRoles.ONE_SB+UserRoles.ADMIN]
      },
       //API-End-Point
       {
        heading: "API End Points Configuration",
        route: "/settings/account/ConfigureApiEndPoints",
        fontIcon: "bi bi-gear-wide",
        permission: [OrgRoles.ONE_SB+UserRoles.ADMIN]
      },
      {
        heading: "View Organization API",
        route: "/settings/organizationapis",
        fontIcon: "bi-stack",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.CONSUMER+UserRoles.DEVELOPER,OrgRoles.ONE_SB+UserRoles.ADMIN,OrgRoles.ONE_SB+UserRoles.DEVELOPER]
        
      },
//       {
//         heading: "Attribute Setup",
//         route: "/settings/account/attributemaster",
//         fontIcon: "bi-alarm",
//         permission: [OrgRoles.CONSUMER+UserRoles.ADMIN]
//       },
//       {
//         heading: "Rule Engine",
//         route: "/settings/account/ruleengine",
//         fontIcon: "bi-alarm",
//         permission: [OrgRoles.CONSUMER+UserRoles.ADMIN]
//       },

      {
        heading: "Communication Service",
        route: "/communicationService/service",
        fontIcon: "bi-stack",
        permission: [OrgRoles.CONSUMER+UserRoles.ADMIN,OrgRoles.CONSUMER+UserRoles.DEVELOPER]

      }
    ]
  },
];

export default DocMenuConfig;
